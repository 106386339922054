// These polyfills targets old browser that does not support newer features (after 2015).
// This entry is imported with the 'nomodule' attribute, so new browser will not download this code.
// For more recent polyfills (post ES2015, see src/utils/polyfills.js)
import 'core-js/web/url-search-params';
import 'core-js/features/array/includes';
import 'core-js/features/array/map';
import 'core-js/features/array/from';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/string/includes';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'whatwg-fetch';

/*
  Element.matches polyfill
  https://davidwalsh.name/element-matches-selector
*/
if (!Element.prototype.matches) {
  const ep = Element.prototype;
  ep.matches = ep.webkitMatchesSelector || ep.matchesSelector || ep.mozMatchesSelector || ep.msMatchesSelector || ep.oMatchesSelector;
}

/*
  requestAnimationFrame polyfill
   http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
*/
(function() {
  var lastTime = 0;
  var vendors = ['webkit', 'moz'];
  for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
    window.cancelAnimationFrame =
      window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function(callback) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function() { callback(currTime + timeToCall); },
        timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function(id) {
      clearTimeout(id);
    };
}());

/*
  window.location.origin polyfill
*/
window.location.origin ||= window.location.protocol + "//"
  + window.location.hostname
  + (window.location.port ? ':' + window.location.port : '');
